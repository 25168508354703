import React from 'react';
import '../styles/Header.css';
import logo_diag from '../assets/DIAGRAM_logo.svg'
import Language from "./Language/Language";
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";

const MainMenu = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const currentLanguage = i18n.language;

  const handleRefreshClick = () => {
    const urlPath = currentLanguage === 'pl' ? '/konwerter' : '/converter';
    navigate(urlPath);
  };

  return (
    <div className="mainMenu">
      {/* <button className='mainMenu-button'>{t('KnowledgeBase')}</button> */}
      <a className='mainMenu-button' href='/' >DIAG</a> {/* to do add /en redirection when welcome page is ready */}
      <div style={{ width: '0.5px', height: '14px', background: '#000' }} />
      <button className='mainMenu-button1' onClick={handleRefreshClick}>{t('converter')} </button>
    </div>
  );
};

const Header = () => {
  const { t } = useTranslation();
  return (
    <>
      <header className="header">
        <img src={logo_diag} alt="Logo_diag" className="diaglogo" />
        <div>
          <p className='shultz'>{t("shultz1")}<br />
            <span style={{ marginLeft: "20px" }}>{t("shultz2")}</span>
            <br />
            <br />
            <span style={{fontStyle:"normal"}}>{t("shultz3")} </span>
          </p>
          <Language />
        </div>
      </header>
      <MainMenu />
      <div className="color-converter">{t('colorConverter')}</div>
    </>
  );
};

export default Header;
